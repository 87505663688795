/**
 * Helper functions for string-based dates/intervals and null-based intervals.
 */

import {
  addDays,
  differenceInWeeks,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  format,
  isAfter,
  isBefore,
  isEqual,
  max,
  min,
  parseISO,
  startOfWeek,
} from 'date-fns';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';

export function resolveISO(dateStringOrObjectOrNull) {
  return dateStringOrObjectOrNull && ((dateStringOrObjectOrNull instanceof Date)
    ? dateStringOrObjectOrNull : parseISO(dateStringOrObjectOrNull));
}

export function resolveSeconds(timeStringOrNumber) {
  return Number.parseFloat(timeStringOrNumber);
}

export function resolveISORange(rangeObjectOrNull) {
  if (!rangeObjectOrNull) {
    return rangeObjectOrNull;
  }
  return {
    start: resolveISO(rangeObjectOrNull && rangeObjectOrNull.start),
    end: resolveISO(rangeObjectOrNull && rangeObjectOrNull.end),
  };
}

export function formatISORange(rangeObject) {
  return {
    start: (rangeObject.start) ? format(rangeObject.start, 'yyyy-MM-dd') : null,
    end: (rangeObject.end) ? format(rangeObject.end, 'yyyy-MM-dd') : null,
  };
}

export function formatISODateTimeRange(rangeObject) {
  return {
    start: (rangeObject.start) ? format(rangeObject.start, 'yyyy-MM-dd HH:mm:ss') : null,
    end: (rangeObject.end) ? format(rangeObject.end, 'yyyy-MM-dd HH:mm:ss') : null,
  };
}

export function formatISO(date) {
  return (date) ? format(date, 'yyyy-MM-dd') : date;
}

export function formatTime(date) {
  return (date) ? format(date, 'HH:mm') : date;
}

export function formatDateTime(date) {
  return (date) ? format(date, 'yyyy-MM-dd HH:mm') : date;
}

export function formatMillis(date) {
  return (date) ? format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXX") : date;
}
//
// Ranges
//
export function intervalFromRange(range) {
  return {
    start: range.start,
    end: (range.end) ? addDays(range.end, -1) : range.end,
  };
}

export function eachDayOfRange(range, options) {
  if (!range || !range.start || !range.end) {
    // We cannot support unterminated ranges
    return [];
  }
  return eachDayOfInterval(intervalFromRange(range), options);
}

export function eachWeekOfRange(range, options) {
  if (!range || !range.start || !range.end) {
    // We cannot support unterminated ranges
    return [];
  }
  return eachWeekOfInterval(
    intervalFromRange(range),
    { ...options, weekStartsOn: 1 },
  );
}

export function eachMonthOfRange(range) {
  if (!range || !range.start || !range.end) {
    // We cannot support unterminated ranges
    return [];
  }
  return eachMonthOfInterval(
    intervalFromRange(range),
  );
}

export function isWithinRange(d, range) {
  if (!range) {
    return false; // XXX is a null-range everything (true) or nothing (false)?
    // eslint-disable-next-line no-else-return
  } else if (range.start && !range.end) {
    // Start is inclusive
    return isEqual(d, range.start) || isAfter(d, range.start);
  } else if (!range.start && range.end) {
    // End is exclusive
    return isBefore(d, range.end);
  } else if (!range.start && !range.end) {
    // EVERYTHING
    return true;
  } else {
    return !isBefore(d, range.start) && isBefore(d, range.end);
  }
}

export function getOverlappingRange(...ranges) {
  if (ranges.some((r) => r === null)) {
    return null;
  }

  // Return the smallest range which covers both r1 and r2
  const enders = ranges.map((r) => r.end).filter((d) => d);
  const starters = ranges.map((r) => r.start).filter((d) => d);

  const latestStart = (starters.length > 0) ? max(starters) : null;
  const earliestEnd = (enders.length > 0) ? min(enders) : null;

  if (!latestStart || !earliestEnd || isBefore(latestStart, earliestEnd)) {
    return { start: latestStart, end: earliestEnd };
  }
  return null;
}

export function getCombinedRanges(...ranges) {
  if (ranges.some((r) => r === null)) {
    return null;
  }

  // Return the largest range which covers both r1 and r2
  const enders = ranges.map((r) => r.end).filter((d) => d);
  const starters = ranges.map((r) => r.start).filter((d) => d);

  const latestStart = (starters.length > 0) ? min(starters) : null;
  const earliestEnd = (enders.length > 0) ? max(enders) : null;

  if (!latestStart || !earliestEnd || isBefore(latestStart, earliestEnd)) {
    return { start: latestStart, end: earliestEnd };
  }
  return null;
}

export function areRangesOverlapping(r1, r2) {
  return getOverlappingRange(r1, r2) !== null;
}

export function isEqualRange(r1, r2) {
  if (r1 === null || r2 === null) {
    return r1 === r2;
  }

  return isEqual(r1.start, r2.start) && isEqual(r1.end, r2.end);
}

export function isContainedBy(r, containedBy) {
  return isEqualRange(r, getOverlappingRange(r, containedBy));
}

export function differenceInNullableWeeks(d1, d2, defaultDifference = 0) {
  if (d1 === null || d2 === null) {
    return defaultDifference;
  }
  return differenceInWeeks(d1, d2);
}

export function monday(date) {
  return startOfWeek(date, { weekStartsOn: 1 });
}

export const useDateTimeFormatter = (locale, year = undefined) => computed(
  () => new Intl.DateTimeFormat(locale.value, {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    year,
  }),
);
export const useDateFormatter = (locale, year = undefined) => computed(
  () => new Intl.DateTimeFormat(locale.value, {
    day: 'numeric',
    month: 'long',
    year,
  }),
);

export const useWeekDayDateFormatter = (locale, year = undefined) => computed(
  () => new Intl.DateTimeFormat(locale.value, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year,
  }),
);
export const useWeekDayFormatter = (locale, dayFormat = 'long') => computed(
  () => new Intl.DateTimeFormat(locale.value, {
    weekday: dayFormat,
  }),
);
export const useTimeFormatter = (locale, hourFormat = 'numeric') => computed(
  () => new Intl.DateTimeFormat(locale.value, {
    hour: hourFormat || 'numeric',
    minute: '2-digit',
  }),
);

export const useLocaleDateTimeFormatter = (locale, options) => computed(
  () => new Intl.DateTimeFormat(locale.value, options),
);

export const useSecondsFormatter = (locale, digits = 2) => computed(
  () => new Intl.NumberFormat(locale.value, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style: 'decimal',
  }),
);

export default function useNow(interval = 1000) {
  const now = ref(new Date());

  let timer = null;

  onMounted(() => {
    setTimeout(() => {
      timer = setInterval(() => {
        now.value = new Date();
      }, 500);
    }, interval - new Date().getMilliseconds());
  });

  onBeforeUnmount(() => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  });

  return now;
}
