export function sum(numbers) {
  return numbers.reduce((total, number) => total + number, 0);
}

export function mean(numbers) {
  const { length } = numbers;
  return (length > 0) ? sum(numbers) / length : NaN;
}

export function median(numbers) {
  const sorted = numbers.slice();
  sorted.sort((a, b) => a - b);
  const halfway = (sorted.length - 1) / 2;
  return (halfway >= 0)
    ? mean(sorted.slice(Math.floor(halfway), Math.ceil(halfway) + 1))
    : NaN;
}

export function variance(numbers) {
  const mn = mean(numbers);
  return mean(numbers.map((num) => (num - mn) ** 2));
}

export function standardDeviation(numbers) {
  return Math.sqrt(variance(numbers));
}