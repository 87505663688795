// noinspection JSDeprecatedSymbols

import {
  ref,
  watchEffect,
} from 'vue';

export const useMedia = (query) => {
  const matches = ref(true);

  if (typeof window !== 'undefined') {
    watchEffect((onInvalidate) => {
      const media = window.matchMedia(query);

      if (media.matches !== matches.value) {
        matches.value = media.matches;
      }

      const onChange = () => {
        matches.value = media.matches;
      };

      if (media.addEventListener) {
        media.addEventListener('change', onChange);

        onInvalidate(() => {
          media.removeEventListener('change', onChange);
        });

      } else if (media.addListener) {
        media.addListener(onChange);

        onInvalidate(() => {
          media.removeListener(onChange);
        });
      }
    });
  }

  return matches;
};

export default useMedia;
