/* eslint-disable func-names */
import {
  alphaNum,
  helpers,
  numeric,
} from '@vuelidate/validators';

export const validateIf = (prop, validator) => helpers.withParams({
  type: 'validatedIf',
  prop,
}, function (value) {
  return this[prop] ? validator(value) : true;
});
export const validateIfNot = (prop, validator) => helpers.withParams({
  type: 'validateIfNot',
  prop,
}, function (value) {
  return !this[prop] ? validator(value) : true;
});

export const validChipNumber = (v) => ((v.length === 10 && alphaNum.$validator(v))
  || (v.length === 15 && numeric.$validator(v)));

export class ValidationError extends Error {
  constructor(state, failures) {
    super(`Validation error: ${JSON.stringify(state || {})} + ${JSON.stringify(failures || {})}`);
    this.state = state;
    this.failures = failures;
  }

}

function failuresFromError(error) {
  return [{
    message: 'Systeemfout: foutmelding is opgeslagen voor analyse; we hopen het zo snel mogelijk voor je te repareren.',
    originalError: error,
  }];
}

function failuresFromNetworkError(error) {
  return [{
    message: 'Netwerkfout: we konden de server niet bereiken door een netwerkprobleem, controleer je verbinding en probeer het later nog eens.',
    originalError: error,
  }];
}

function stateFromData(data, options) {
  const aliases = options?.aliases || {};
  const _field = (f) => (aliases[f] || f);
  // If it is OperationResponse, we had errors
  if (data?.kind === 'PERMISSION' || data?.kind === 'VALIDATION' || data?.kind === 'ERROR') {
    if (data.kind === 'PERMISSION') {
      throw new ValidationError({}, data.messages);
    }
    const walk = (d) => {
      const s = {};
      d.fields?.forEach((f) => {
        s[_field(f.field)] = walk(f);
      });

      if (d.messages && d.field && !d.fields) {
        return d.messages;
      }
      if (d.messages) {
        s.null = d.messages;
      }

      return s;
    };

    throw new ValidationError(walk(data), []);
  }
  return data;
}

export function parseMutationResult({ data, error }, options = null) {
  if (error?.networkError) {
    if (import.meta.env.DEV) {
      console.error(error.networkError);
    }
    // Network error, probably no connection. Try again.
    throw new ValidationError({}, failuresFromNetworkError(error.networkError));
  } else if (error) {
    if (import.meta.env.DEV) {
      console.error(error);
    }
    // Hard GQL error, most likely a schema error.
    throw new ValidationError({}, failuresFromError(error));
  } else if (data) {
    // We got data, but it might contain validation errors.
    return Object.fromEntries(Object.entries(data).map(
      ([key, value]) => [key, stateFromData(value, options)],
    ));
  }
  throw new ValidationError({}, [{ message: 'Onbekende fout' }]);
}

export function getMessages(ve) {
  if (!ve.state) {
    throw ve;
  }
  return Object.values(ve.state).filter((m) => m instanceof Array)
    .reduce((messages, m) => [...messages, ...m], []);
}

// Stub for making vuelidate validate the field
export function testExternal() {
  return () => true;
}

export const defaultTest = {
  default: () => true,
};
