export function mergeUpdates(current, updates, objGetter) {

  const updated = current.slice();

  updates.forEach((u) => {
    if (u.updateType === 'UPDATE') {
      const obj = objGetter(u);
      const idx = updated.findIndex((o) => o.id === u.objectId);
      if (idx > -1) {
        updated.splice(idx, 1, obj);
      } else {
        updated.push(obj);
      }
    } else if (u.updateType === 'DELETE') {
      const idx = updated.findIndex((o) => o.id === u.objectId);
      if (idx > -1) {
        updated.splice(idx, 1);
      }
    }
  });

  return updated;
}
