import { computed } from 'vue';

export const currencyFormatter = computed(
  () => new Intl.NumberFormat(
    'nl-NL',
    { style: 'currency', currency: 'EUR' },
  ),
);

export default currencyFormatter;
