import {
  isDate,
  isEqual,
} from 'date-fns';

export function isObject(object) {
  return object != null && typeof object === 'object';
}

export function deepEqual(a, b) {
  if (isDate(a) || isDate(b)) {
    return isEqual(a, b);
  }

  if (a === null || b === null || typeof a !== 'object' || typeof b !== 'object') {
    return a === b;
  }

  if (typeof a === 'object' && typeof b === 'object') {
    // Array or object, check keys length and every key
    return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every(
      (k) => deepEqual(a[k], b[k]),
    );
  }

  console.error(`No clue how to deal with '${a}' or '${b}'`);
  return false;
}
