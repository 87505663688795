function parseResult(data) {
  const location = data[0];
  const { address } = location;

  const localAddress = `${address.cycleway || address.road} ${address.house_number || ''}`;
  return {
    latitude: location.lat,
    longitude: location.lon,
    address: localAddress,
    postalCode: address.postcode,
    city: address.city || address.town || address.village || address.municipality || address.suburb,
  };
}

export async function nominatimQuery(address, postalCode, city, country) {
  const Nominatim = await import(
    'nominatim-browser'
  );

  let result = {
    address,
    postalCode,
    city,
    country,
    latitude: null,
    longitude: null,
  };
  const q = `${address.trim()}, ${postalCode.trim()}, ${city.trim()}`;
  try {
    const data = await Nominatim.geocode({
      q,
      addressdetails: true,
      countrycodes: country.id,
    });

    // We want only 1 result
    if (data && data.length === 1) {
      result = parseResult(data);
    }
  } catch (e) {
    // We pass to the error return
    console.log(e);
  }
  return result;

}
