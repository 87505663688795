export function debounce(fn, delay = 0, immediate = false) {
  let timeout;
  return (...args) => {
    if (immediate && !timeout) {
      fn(...args);
    }
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn(...args);
      timeout = null;
    }, delay);
  };
}

export function throttle(fn, delay = 0) {
  let timeout;
  return (...args) => {
    if (!timeout) fn(...args);
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      clearTimeout(timeout);
      timeout = null;
    }, delay);
  };
}

export function smooth(fn, delay = 0) {
  let timeout;
  return (...args) => {
    if (!timeout) {
      fn(...args);
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        timeout = null;
      }, delay);
    }
  };
}
