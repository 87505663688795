export function findLast(array, cb) {
  if (array.findLast) {
    return array.findLast(cb);
  }

  if (!array?.length) {
    return undefined;
  }

  let found = false;
  let idx = array.length - 1;
  while (!found && idx >= 0) {
    found = cb(array[idx], idx, array);
    idx -= 1;
  }
  if (found) {
    return array[idx + 1];
  }
  return undefined;
}

export function toSorted(array, compareFn) {
  if (!array) {
    return array;
  }
  const copy = array.slice();
  copy.sort(compareFn);
  return copy;
}
