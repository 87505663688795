function stringSum(n) {
  let v = n;
  while (v >= 10) {
    v = [...`${v}`].reduce((c, d) => c + Number.parseInt(d, 10), 0);
  }
  return v;
}
export function isLHBNumber(text) {
  const elf = [...text].slice(0, text.length - 1).reduce(
    (v, digit, idx) => v + Number.parseInt(digit, 10) * ((11 - idx)),
    0,
  );
  const textDigit = Number.parseInt([...text][text.length - 1], 10);
  return stringSum(elf) === textDigit;
}

export function isCombinationNumber(text) {
  if (text > '300000' && text < '500000') {
    return isLHBNumber(text);
  }

  const elf = [...text].slice(0, text.length - 1).reduce(
    (v, digit, idx) => v + Number.parseInt(digit, 10) * ((text.length - idx)),
    0,
  );
  const digit = ((11 - (elf % 11)) % 11);
  const textDigit = Number.parseInt([...text][text.length - 1], 10);
  return digit === textDigit;
}
