import {
  getDate,
  getMonth,
  getYear,
} from 'date-fns';
import { computed } from 'vue';
import slugify from 'slugify';

export function getDateFromRoute(route) {
  // noinspection JSCheckFunctionSignatures
  return computed(() => ((route?.params?.year) ? new Date(
    Number.parseInt(route.params.year, 10),
    Number.parseInt(route.params.month, 10) - 1,
    Number.parseInt(route.params.day, 10),
  ) : null));

}

export function getCalendarRoute(locale, sport = null, fields = {}) {
  return {
    name: `${locale}:calendar:week`,
    params: {
      sport: (sport) ? slugify(sport, { lower: true }) : undefined,
      ...fields,
    },
  };
}

export function getEventRoute(event, locale, fields = {}, view = 'event') {
  return {
    name: `${locale}:${view}`,
    params: {
      year: getYear(event.duration.start),
      month: getMonth(event.duration.start) + 1,
      day: getDate(event.duration.start),
      organiser: event.organiser.slug,
      ...fields,
    },
  };
}

export function getGroupGradeRoute(event, show, groupGrade, locale, fields = {}, view = 'groupGrade') {
  const eventRoute = getEventRoute(event, locale, {}, view);
  return {
    name: `${locale}:${view}`,
    params: {
      ...eventRoute.params,
      year: getYear(show.duration.start),
      month: getMonth(show.duration.start) + 1,
      day: getDate(show.duration.start),
      show: show.slug,
      groupGrade: groupGrade.grade.slug,
      ...fields,
    },
  };
}

export function getGroupRoute(event, show, groupGrade, group, locale, fields = {}, view = 'groupGrade:group') {
  const eventRoute = getEventRoute(event, locale, {}, view);
  return {
    name: `${locale}:${view}`,
    params: {
      ...eventRoute.params,
      year: getYear(show.duration.start),
      month: getMonth(show.duration.start) + 1,
      day: getDate(show.duration.start),
      show: show.slug,
      groupGrade: groupGrade.grade.slug,
      group: group.slug,
      ...fields,
    },
  };
}

export function getCourseRoute(event, show, group, course, locale, fields = {}, view = 'course') {
  const eventRoute = getEventRoute(event, locale, {}, view);
  return {
    name: `${locale}:${view}`,
    params: {
      ...eventRoute.params,
      year: getYear(event.duration.start),
      month: getMonth(event.duration.start) + 1,
      day: getDate(event.duration.start),
      eventDay: getDate(course.duration.start),
      show: show.slug,
      group: group.slug,
      course: course.slug,
      ...fields,
    },
  };
}

export function getResultListRoute(event, show, group, resultList, locale, fields = {}, view = 'resultList') {
  const eventRoute = getEventRoute(event, locale, {}, view);
  return {
    name: `${locale}:${view}`,
    params: {
      ...eventRoute.params,
      year: getYear(event.duration.start),
      month: getMonth(event.duration.start) + 1,
      day: getDate(event.duration.start),
      eventDay: getDate(show.duration.start),
      show: show.slug,
      group: group.slug,
      resultList: resultList.slug,
      ...fields,
    },
  };
}

export function getEventManageRoute(event, locale, tab = null, fields = {}) {
  return getEventRoute(event, locale, fields, (tab) ? `event:manage:${tab}` : 'event:manage');
}

export function getShowManageRoute(event, show, locale, fields = {}) {
  const route = getEventRoute(event, locale, fields, 'event:manage:show');

  return {
    name: route.name,
    params: {
      ...route.params,
      show: show.slug,
      showDay: getDate(show.duration.start),
      ...fields,
    },
  };
}

export function getSchemaEventDayManageRoute(event, date, locale, fields = {}) {
  const route = getEventRoute(event, locale, fields, 'event:manage:schemaEventDay');

  return {
    name: route.name,
    params: {
      ...route.params,
      eventDay: getDate(date),
      ...fields,
    },
  };
}

export function getResultsEventDayManageRoute(event, date, locale, fields = {}) {
  const route = getEventRoute(event, locale, fields, 'event:manage:resultsEventDay');

  return {
    name: route.name,
    params: {
      ...route.params,
      eventDay: getDate(date),
      ...fields,
    },
  };
}

export function getResultsCourseManageRoute(event, date, show, group, course, locale, fields = {}) {
  const route = getEventRoute(event, locale, fields, 'event:manage:resultsCourse');

  return {
    name: route.name,
    params: {
      ...route.params,
      eventDay: getDate(date),
      show: show.slug,
      group: group.slug,
      course: course.slug,
      ...fields,
    },
  };
}

export function getClubManageRoute(club, locale, tab = null, fields = {}) {
  return {
    name: (tab) ? `${locale}:club:manage:${tab}` : `${locale}:club:manage`,
    params: {
      club: club.slug,
      ...fields,
    },
  };
}

export function getCombinationRoute(combination, locale, tab, fields = {}, view = 'combination') {
  return {
    name: (tab) ? `${locale}:${view}:${tab}` : `${locale}:${view}`,
    params: {
      dog: combination.dog.slug,
      handler: combination.handler.slug,
      ...fields,
    },
  };
}

export function getRankingManageRoute(ranking, locale, tab = null, fields = {}) {
  return {
    name: (tab) ? `${locale}:ranking:manage:${tab}` : `${locale}:ranking:manage`,
    params: {
      slug: ranking.slug,
      years: ranking.years,
      sport: ranking.rankingGroup.sport.toLocaleLowerCase(),
      ...fields,
    },
  };
}
