import {
  onMounted,
  onUnmounted,
} from 'vue';

export function useShortcuts() {

  const shortcuts = new Map();

  const keyDown = (event) => {
    const responses = [...shortcuts.entries()].filter(
      (shortcut) => Object.entries(shortcut[1]).every(([k, v]) => event[k] === v),
    ).map(([callback]) => callback(event)).filter((v) => !!v);
    // If we captured, we prevent other calls
    if (responses.length > 0) {
      event.preventDefault();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', keyDown);
  });
  onUnmounted(() => {
    shortcuts.clear();

    window.removeEventListener('keydown', keyDown);
  });

  return {
    onShortcut(shortcut, callback) {
      shortcuts.set(callback, shortcut);

      return () => shortcuts.delete(callback);
    },
  };
}

export default useShortcuts;
